<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-row justify="space-between">
          <el-col :span="20">
            <el-menu
              default-active="1"
              @open="handleOpen"
              @close="handleClose"
              mode="horizontal"
              text-color="#000000"
              active-text-color="#000000"
            >
              <el-menu-item
                ref="appList"
                :index="1"
                @click="handleAppList"
                :disabled="appStatus"
              >
                <el-icon><icon-menu /></el-icon>
                <span>App</span>
              </el-menu-item>
              <el-menu-item
                ref="user"
                :index="2"
                @click="handleUser"
                :disabled="userStatus"
                v-show="userShow"
              >
                <el-icon><document /></el-icon>
                <span>User</span>
              </el-menu-item>
              <el-menu-item
                ref="manufacturer"
                :index="3"
                @click="handleManufacturer"
              >
                <el-icon><document /></el-icon>
                <span>Manufacturer</span>
              </el-menu-item>
              <el-menu-item ref="developer" :index="4" @click="handleDeveloper">
                <el-icon><document /></el-icon>
                <span>Developer</span>
              </el-menu-item>
              <el-menu-item ref="spider" :index="5" @click="handleStat">
                <el-icon><document /></el-icon>
                <span>Stat</span>
              </el-menu-item>
              <el-menu-item ref="spider" :index="6" @click="handleSpider">
                <el-icon><document /></el-icon>
                <span>Spider</span>
              </el-menu-item>
            </el-menu>
          </el-col>

          <el-col :span="4">
            <el-button @click="logoutHandle" id="logout-button"
              >Logout</el-button
            >
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-container>
          <el-main>
            <!-- <keep-alive :max="10"> -->
            <router-view />
            <!-- </keep-alive> -->
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { cleanLoginInfo, getUserId, getUserName } from "../utils/auth";
import { getRole } from "../utils/permission";
import enumValue from "../utils/enum";
// import child2 from "./Child2.vue"
export default {
  name: "hello",
  components: {
    // child2,
  },
  data() {
    return {
      userShow: false,
      appStatus: false,
      userStatus: false,
      manufacturerStatus: false,
    };
  },
  mounted() {
    this.checkPermission();
  },
  methods: {
    handleAppList() {
      if (this.$route.name != "appList") {
        this.$router.push({ name: "appList" });
      }
    },
    handleUser() {
      if (this.$route.name != "userList") {
        this.$router.push({ name: "userList" });
      }
    },
    handleManufacturer() {
      if (this.$route.name != "manufacturerList") {
        this.$router.push({ name: "manufacturerList" });
      }
    },
    handleDeveloper() {
      if (this.$route.name != "developerList") {
        this.$router.push({ name: "developerList" });
      }
    },
    checkPermission() {
      const role = getRole(getUserId());
      if (role != enumValue.permissionAdmin) {
        this.userShow = false;
      } else {
        this.userShow = true;
      }
    },
    logoutHandle() {
      cleanLoginInfo();
      this.$router.push({ name: "login" });
    },
    getName() {
      return getUserName();
    },
    handleSpider() {
      if (this.$route.name != "spider") {
        this.$router.push({ name: "spiderList" });
      }
    },
    handleStat() {
      if (this.$route.name != "stat") {
        this.$router.push({ name: "statInfo" });
      }
    },
  },
};
</script>



<style scoped>
.el-header {
  background-color: #0077ed;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 40px;
  height: 50px;
  padding: 0;
}
.el-footer {
  background-color: #000000;
  /* background-color: #55caf8; */
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 40px;
  height: 40px;
}
.el-menu {
  background-color: rgba(250, 12, 12, 0);
  /* background-color: rgb(0, 119, 237, 0); */
  color: var(--el-text-color-primary);
  width: 100%;
  height: 50px;
  font-weight: bold;
}
.el-aside {
  background-color: "#b3c0d1";
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 200px;
}

.el-main {
  /* background-color: #e9eef3; */
  background-color: none;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: auto;
  /* height: 840px; */
  padding: 3px;
  padding-top: 0;
}
#logout-button {
  color: white;
  background: rgba(255, 255, 255, 0);
  font-weight: bold;
  border: 0;
  width: 50px;
  height: 50px;
  font-size: 16px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>
