<template>
  <div>
    <router-view />
  </div>
</template>

<script>
</script>

<style>
.el-pagination {
  justify-content: center;
}
</style>